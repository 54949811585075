import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layouts/main-layout";
import SportsLayout from "../../components/layouts/sports-layout";
import CourseInfo from "../../components/course-info";
import Seo from "../../components/seo";

const Kitesurfen = () => {
  const headerImage = (
    <StaticImage
      src="../../images/heroimages/tidens_KTS_header.jpg"
      alt="Kitesurfen bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  const description = (
    <div>
      Spüre das Adrenalin und das spektakuläre Gefühl, auf einem kleinen Bord
      vom Kite über das Wasser gezogen zu werden. Kitesurfen ist eine sehr
      dynamische Sportart und der Kite ist deine direkte Verbindung zum Wind.
      Was zunächst komplex klingt, ist mit gutem Training relativ schnell zu
      erlernen und bietet dir ab der ersten Unterrichtsstunde Action pur!
    </div>
  );

  const rentalDescription =
    "Du hast schon ausreichend Kenntnisse, um alleine zu üben? Gegen Vorlage einer VDWS-Lizenz oder Ähnlichem kannst du dir bei uns auch Kitesurfmaterial ausleihen.";
  const courses = [
    <CourseInfo
      title="Schnupperkurs"
      price="199"
      duration="4h (1 Tag) "
      people="4"
      description="Dieser Kurs ist für alle, die einmal ins Kitesurfen hineinschnuppern wollen und diese spannende Sportart kennenlernen möchten. Im Schnupperkurs vermitteln wir dir an Land die allgemeine Sicherheit und üben mit einem Trainerkite die Kitesteuerung. Anschließend gehen wir gemeinsam ins Wasser, machen die ersten Flugübungen mit einem großen Kite und lassen uns von diesem (ohne Brett) durchs Wasser ziehen (VDWS-Level 1). Nach Abschluss des Kurses kannst du bei uns eine VDWS-Lizenz ablegen (Level 1)."
      sport="KTS"
      type="TRY"
      requestOnly={false}
    />,
    <CourseInfo
      title="Einsteigerkurs"
      price="329"
      duration="7h (2 Tage)"
      people="4"
      description="Der Kitesurf Einsteigerkurs bietet an zwei Tagen einen Einstieg in die komplexe Sportart Kitesurfen. In diesem Kurs lernst du die Grundlagen im Umgang mit dem Kite, sowohl an Land als auch im Wasser. Ziel ist es, dass du sicher deinen Kite unter Kontrolle hast (VDWS-Level 2), um in einem Folgekurs auch das Starten mit dem Brett und das Fahren zu lernen. Nach Abschluss des Kurses kannst du bei uns eine VDWS-Lizenz ablegen (Level 2)."
      sport="KTS"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="VDWS Grundkurs"
      price="449"
      duration="10h (3 Tage)"
      people="4"
      description="Der VDWS-Grundkurs Kitesurfen ist für alle geeignet, die noch keine Erfahrung im Kitesurfen haben und diese Sportart erlernen möchten. In diesem Kurs lernst du die Grundlagen im Umgang mit dem Kite, sowohl an Land als auch im Wasser. Anschließend trainieren wir gemeinsam den Wasserstart. Ziel ist es, dass du deine ersten Meter auf dem Board fährst (VDWS-Level 3). Nach Abschluss des Kurses kannst du bei uns eine VDWS-Lizenz ablegen (Level 3)."
      sport="KTS"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Aufbaukurs"
      price="ab 149"
      duration={[
        <div className="pt-2">3h 149€ (1 Tag)</div>,
        <div>6h 285€ (2 Tage)</div>,
        <div className="mb-2">9h 410€ (3 Tage)</div>,
      ]}
      people="4"
      description="Du hast bei uns einen Schnupperkurs / Einsteigerkurs absolviert und möchtest jetzt Fahren lernen? Im Aufbaukurs konzentrieren wir uns auf die Kontrolle des Kites (Bodydrag und Starten des Kites aus dem Wasser) sowie auf den Wasserstart mit dem Board (VDWS-Level 2-3). Der Aufbaukurs ist außerdem optimal für alle geeignet, die bereits einen Grundkurs absolviert haben, es aber noch nicht auf das Brett geschafft haben (VDWS-Level 3)."
      sport="KTS"
      type="ADV"
      requestOnly={false}
    />,
    <CourseInfo
      title="Fortgeschrittenen Kurs"
      price="149"
      duration="2h"
      people="2"
      description="In diesem Kurs bist du richtig, wenn du mindestens das VDWS-Level 4 erreicht hast (Raumschot fahren, Steuern, Höhe halten, Basishalse) und deine Fahrkünste nun weiter ausbauen und verbessern möchtest. Im Fortgeschrittenenkurs lernst du Höhe zu gewinnen (Kreuzen) sowie fortgeschrittene Inhalte wie Richtungswechsel oder deine ersten Sprünge. Der Inhalt des Kitekurses richtet sich dabei ganz nach deinen Bedürfnissen (VDWS-Level 5-7). Nach Möglichkeit trainieren wir mit einem Kite pro Schüler."
      sport="KTS"
      type="FRT"
      requestOnly={false}
    />,
    <CourseInfo
      title="Kinder Kitekurs"
      price="99"
      duration="1h"
      people="1"
      description="Durch den Einsatz des Flysurfer Hybrid Kites können auch Kinder bei uns Kiten lernen. Den Kinderkitekurs bieten wir nur auf Anfrage als Privatstunde an."
      requestOnly={true}
    />,
    <CourseInfo
      title="Einführung Softkites"
      price="189"
      duration="2h"
      people="1"
      description="Die Welt der Sofkites (Matten) ist dir noch unbekannt, aber Du möchtest Sie kennenlernen? Wir zeigen Dir alle Grundlagen (Auf- & Abbau, Starten, Landen), die Unterschiede bei der Fahrtechnik sowie Trimtechniken (Mixertest). Bei Kauf eines neuen Softkites rechnen wir den Kurs bis zu 10% des UVP des Kites an."
      requestOnly={true}
    />,
    <CourseInfo
      title="Privatstunde"
      price="ab 79"
      duration={[
        <div className="pt-2">1h 79€ (Eigenes Material)</div>,
        <div className="mb-2">1h 119€ (Mietmaterial)</div>,
      ]}
      people="1"
      description="Du möchtest etwas ganz Bestimmtes lernen, die ersten Tricks und Sprünge üben oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne unterrichten wir dich auch in einer Privatstunde. Bitte beachte, dass wir Privatstunden nur auf Anfrage anbieten können."
      requestOnly={true}
    />,
    <CourseInfo
      title="Begleitetes Kiten"
      price="ab 39"
      duration={[
        <div className="pt-2">1h 39€ (Eigenes Material)</div>,
        <div className="mb-2">1h 79€ (Mietmaterial)</div>,
      ]}
      people="2"
      description="Du bist bereits so weit, alleine zu üben, aber möchtest, dass noch jemand dabei ist, der nach Dir schaut? Dann ist das begleitete Kiten das richtige für dich. "
      requestOnly={true}
    />,
    <CourseInfo
      title="VDWS Lizenz"
      price="60"
      duration="1h"
      description="Im Anschluss an den Grundkurs kannst du bei uns auch eine VDWS-Lizenz machen. Diese gilt als Nachweis für deine Fähigkeiten im Bereich Kitesurfen und wird zum Beispiel für das Ausleihen von Material benötigt. Im Preis sind das Work- und Stylebook Kitesurfen, die Prüfung sowie die Lizenz enthalten."
      requestOnly={true}
    />,
  ];

  const image1 = (
    <StaticImage
      src="../../images/galery/kite/tidensKTSgalery-1.jpg"
      alt="Kitesurfen lernen bei Tidens Surfhuus Galerybild 1"
      width={465}
      height={700}
      quality={90}
      orientation="portrait"
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image2 = (
    <StaticImage
      src="../../images/galery/kite/tidensKTSgalery2.jpg"
      alt="Kitesurfkurse bei Tidens Surfhuus Galerybild 2"
      width={465}
      height={700}
      quality={90}
      orientation="portrait"
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image3 = (
    <StaticImage
      src="../../images/galery/kite/tidensKTSgalery-3.jpg"
      alt="Kitesurfkurse bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image4 = (
    <StaticImage
      src="../../images/galery/kite/tidensKTSgalery-4.jpg"
      alt="Kitesurfkurse bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const image5 = (
    <StaticImage
      src="../../images/galery/kite/tidensKTSgalery-5.jpg"
      alt="Kitesurfkurse bei Tidens Surfhuus Galerybild 2"
      width={700}
      height={465}
      quality={90}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[25vw]"
    />
  );

  const images = [image4, image1, image2, image3, image5];

  return (
    <Layout>
      <Seo
        title="Kitesurf Kurse für Anfänger und Fortgeschrittene"
        description="Du möchtest Kitesurfen lernen? Wir bieten Kitesurfkurse für Anfänger und Fortgeschrittene auf der Nordseeinsel Langeoog an."
      />
      <SportsLayout
        title="Kitesurfen"
        sport="kitesurfen"
        image={headerImage}
        description={description}
        rental_description={rentalDescription}
        courses={courses}
        images={images}
      />
    </Layout>
  );
};

export default Kitesurfen;
